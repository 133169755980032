
import React from 'react';
import styled from 'styled-components'


import {
  Center, BtnAnchor,
  Cards, Card, ButtonBox,
} from './atoms'

const EducationWrapper = styled.div`
  background: #2f2f2f;
  padding: 20px 0;
`;

const MiddleBox = styled.div`
  border-top: 1px solid #02b156;
  height: 110px;
  font-size: 24px;

  + :last-child {
    border-top: 1px solid #02b156;
  }
`;

const CardHeader = styled.div`
  font-size: 30px;
  text-align: center;
  padding-bottom: 20px;

  p {
    margin: 0;
    font-size: 38px;
    font-weight: bold;
    color: #02b156;
    line-height: 26px;
  }
`;

const Hilighted = styled.p`
  font-size: 24px;
  padding: 20px 0 0;
  margin: 0
`;

const FullTitle = styled.p`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  padding: 20px 0;
  margin: 0;
`

function App() {

  return (
  <EducationWrapper>
    <Center>
      <FullTitle>
        AGENDA
      </FullTitle>
      <Cards>
        <Card>
          <CardHeader>
            <p>metanoia</p>
            <span><b>meta</b>gestão</span>
          </CardHeader>
          <MiddleBox>
            <p>1 módulo com duração de 1 dia e meio.</p>
          </MiddleBox>
          <MiddleBox>
            <p>Locais: Metanoia/SP ou in-company.</p>
          </MiddleBox>
          <Hilighted>Materiais e livros inclusos</Hilighted>
        </Card>
        <Card>
          <CardHeader>
            <p>metanoia</p>
            <span><b>meta</b>riqueza</span>
          </CardHeader>
          <MiddleBox>
            <p>Início em Agosto de 2024.</p>
          </MiddleBox>
          <MiddleBox>
            <p>Locais: Metanoia/SP ou in-company.</p>
          </MiddleBox>
          <Hilighted>Materiais e livros inclusos</Hilighted>
        </Card>
        <Card>
          <CardHeader>
            <p>metanoia</p>
            <span><b>liderança de alto valor</b></span>
          </CardHeader>
          <MiddleBox>
            <p>Início em Maio de 2024.</p>
          </MiddleBox>
          <MiddleBox>
            <p>Locais: Metanoia/SP ou in-company.</p>
          </MiddleBox>
          <Hilighted>Materiais e livros inclusos</Hilighted>
        </Card>
      </Cards>
      <ButtonBox>
        <BtnAnchor className="button-banner" target="_blank" href="https://api.whatsapp.com/send?phone=5511982629265" rel="noreferrer">Quero saber mais ...</BtnAnchor>
      </ButtonBox>
    </Center>
  </EducationWrapper>
  );
}

export default App;
