
import React, { useState } from 'react';
import styled from 'styled-components'
import Glider from 'react-glider';
import Education from './Components/Education';
import Leadership from './Components/Leadership';
import Scheduler from './Components/Scheduler';
import 'glider-js/glider.min.css';

// import Metanoia from './assets/logo-metanoia.svg';
// import logoMetanoia from './assets/metanoia-horizontal.png';
// import banner_4 from './assets/banner_4.png';
// import banner_5 from './assets/banner_5.png';
// import banner_6 from './assets/banner_6.png';
// import V1 from './assets/v1.png';
// import V2 from './assets/v2.png';
// import V3 from './assets/v3.png';
// import V4 from './assets/v4.png';
// import V5 from './assets/v5.png';
// import AlexandreZorita from './assets/alexandre-zorita.png';
// import EdgardCorona from './assets/edgard-corona.png';

// import Bena from './assets/empresas/bena.png';
// import Bioritmo from './assets/empresas/bioritmo.png';
// import Ideia from './assets/empresas/ideia.png';
// import Granado from './assets/empresas/granado.png';
// import Minnas from './assets/empresas/minnas.png';
// import Quiver from './assets/empresas/quiver.png';
// import Santa from './assets/empresas/santa.png';
// import Viacredi from './assets/empresas/viacredi.png';
// import Sicoob from './assets/empresas/sicoob.png';
// import Real from './assets/empresas/real.png';
// import Btflex from './assets/empresas/btflex.png';
// import I4l from './assets/empresas/4l.png';


import {
  Site, Center, Transformation,
  Cards, Card, HalfBox, Banner,
  Green, Orange, Videos, Leader,
  Head, ButtonBox, CompanyList,
  SquareTitle, Margin40, Team,
  NAV,
} from './atoms';

const BannerItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media(max-width: 900px) {
    grid-template-columns: 1fr;
    position: relative;

    img {
      top: 0;
      left: 0;
      position: absolute;
      z-index: -1;
    }
  }

  > div {    
    @media(max-width: 1023px) {
      background: #222222;
    }

    border-bottom: 54px solid #000;

    @media(max-width: 900px) {
      background: #222222a1;
      border-bottom: 0;
      height: 100%;
    }

    .inner-box {
      width: 500px;
      margin: 0 0 0 auto;
      padding-top: 80px;
      padding-right: 100px;
      position: relative;
      height: calc(100% - 80px);

      &.slide1 h2 {
        max-width: 400px;

        @media(max-width: 900px) {
          margin-top: 50px;
        }
      }

      &.slide2 h2 {
        max-width: 400px;

        @media(max-width: 900px) {
          margin-top: 100px;
        }
      }

      &.slide3 h2 {
        max-width: 450px;

        @media(max-width: 900px) {
          margin-top: 100px;
        }
      }

      &.center {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      @media(max-width: 900px) {
        width: 100%;
        padding: 20px 20px;
        box-sizing: border-box;
      }

      h2 {
        font-size: 40px;
        line-height: 49px;
        font-weight: 600;
        color: #02b156;
        margin: 0 0 25px;

        &.orange {
          color: #f78734;
        }
      }

      p {
        font-size: 21px;
        line-height: 26px;
        font-weight: 400;
        max-width: 420px;
        margin: 0 0 78px;
      }

      .button-banner {
        font-size: 14px;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 24px;

        @media(max-width: 900px) {
          margin-top:20px;
          position: relative;
        }
      }
    }
  }

  @media(max-width: 900px) {
    display: block;
  }
`;

const BannerItemFull = styled.div`
  a {
    // display:flex;
    display: block;
    width: 100%;
  }

  align-items: stretch;
  display:flex;
  background-image: url('/assets/banner_2024.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  @media(max-width: 900px) {
    background-image: url('/assets/banner_2024_m.png');
    background-size: contain;
  }
`;

const BannerItemFull2 = styled.div`
  a {
    // display:flex;
    display: block;
    width: 100%;
  }

  align-items: stretch;
  display:flex;
  background-image: url('/assets/banner_resultados_diferentes.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  @media(max-width: 900px) {
    background-image: url('/assets/banner_mobile-2.png');
    background-size: contain;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  box-sizing: border-box;
`;

const Button = styled.button`
  border: none;
  border-radius: 0;
  background-clip: padding-box;
  background: #F78735;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;
  padding: ${props => props.small ? '5px 16px' : '20px 16px 17px'};
  text-transform: uppercase;

  &:hover {
    background: #000;
    cursor: pointer;
  }
`;

const BtnAnchor = styled.a`
  border: none;
  border-radius: 0;
  background-clip: padding-box;
  background: #F78735;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;
  padding: ${props => props.small ? '5px 16px' : '20px 16px 17px'};
  text-transform: uppercase;
  display: inline-block;

  &:hover {
    background: #000;
    cursor: pointer;
    text-decoration: none !important;
  }
`;

const Footer = styled.footer`
  background: #2F2F2F;
  
  & > div {
    display: flex;
    flex-flow: row wrap;
    padding-top: 48px;
    padding-bottom: 36px;

    @media(max-width: 900px) {
      flex-flow: column-reverse wrap;
      align-items: center;
      justify-content: center;

      .footer-left, .footer-right {
        width: 100%;
      }

      .footer-left {
        margin-top: 40px;
      }

      nav {
        display: none;
      }
    }

    .socials {
      display: flex;
      align-items: center;
      margin-top: -40px;

      a {
        margin-right: 14px;
      }

      svg {
        height: 32px;
        width: 32px;
      }
    }
  }

  nav {
    margin-left: 84px;
    max-height: 115px;
    display: grid;
    grid-template-rows: repeat(4, auto);
    gap: 20px;
    grid-auto-flow: column;
    grid-auto-columns: 125px 100px;

    a {
      height: 15px;
    }
  }

  address {
    padding-top: 26px;
    font-style: normal;
    margin-left: 84px;

    @media(max-width: 900px) {
      margin-left: 0;
      padding-bottom: 26px;
    }
  }

  .footer-left {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, auto);

    .socials {
      grid-column: span 2;
    }

    @media(max-width: 900px) {
      grid-template-columns: repeat(1, auto);
      justify-content: center;
      align-items: center;
      
      .socials {
        grid-column: span 1;
        margin-top: 20px;
      }
    }
  }

  .footer-right {
    width: 50%;

    h3 {
      font-size: 30px;
      color: #F78734;
      font-weight: bold;
      margin: 0 0 10px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      margin: 0 0 21px;
      max-width: 430px;
    }

    button {
      margin-top: 10px;
    }
  }
`;

function App() {
  const [selectedVideo, setSelectedVideo] = useState('https://player.vimeo.com/video/722557592?h=6a30b0ff7c');
  const [selectedCompanyVideo, setSelectedCompanyVideo] = useState('https://player.vimeo.com/video/943719625?h=62ad6acd57&title=0&portrait=0&byline=0');
  const [showMenu, toogleMenu] = useState();

  const scrollToElement = (e, element) => {
    if (e) {
      e.preventDefault();
    }
    document.getElementById(element).scrollIntoView();
    toogleMenu(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = new FormData(event.target);
    const postData = {};

    for (let pair of (form).entries()) {
      postData[pair[0]] = pair[1];
    }

    postData['g-recaptcha-response'] = window.grecaptcha.getResponse();

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(postData)
    };
    
    fetch("https://site-api.metanoia.com.br/send-email", requestOptions)
      .then(response => (response.text()))
      .then(result => {
        const content = JSON.parse(result);
        alert(content.message);
      });
  }

  window.addEventListener('scroll', function() {
    var scroll = window.scrollY;

    if (scroll >= 40) { 
      document.querySelector('header').classList.add('fixed');
    } else {
      document.querySelector('header').classList.remove('fixed');
    }
  });

  return (
    <Site>
      <header>
        <Container>
          <Head>
            <h1><img src="/assets/logo-metanoia.svg" alt="" /></h1>
            <a className="icon mobile-menu" onClick={() => toogleMenu(!showMenu)}>
              <div id="nav-icon3" className={showMenu ? 'open' : ''}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <NAV showMenu={showMenu}>
              <nav>
                <a href="" onClick={(e) => scrollToElement(e, 'desafio')}>Desafio</a>
                <a href="" onClick={(e) => scrollToElement(e, 'transformacao')}>Transformação</a>
                <a href="" onClick={(e) => scrollToElement(e, 'lideranca')}>Liderança</a>
                <a href="" onClick={(e) => scrollToElement(e, 'presenca')}>Presença</a>
                <a href="" onClick={(e) => scrollToElement(e, 'equipe')}>Equipe</a>
                <a href="" onClick={(e) => scrollToElement(e, 'empresa')}>Metanoia</a>
                <a href="" onClick={(e) => scrollToElement(e, 'contato')}>Contato</a>
              </nav>
            </NAV>
          </Head>
        </Container>
      </header>
      <div className="slider--header">
        <Glider
          hasDots
          resizeLock
          slidesToShow={1}
          slidesToScroll={1}
          scrollLock={1}
        >
          <BannerItemFull2>
            <a href="https://www.youtube.com/watch?v=KCXQ-2i_p5M"></a>
          </BannerItemFull2>
          <BannerItemFull>
            <a href="https://api.whatsapp.com/send?phone=5511982629265"></a>
          </BannerItemFull>
          <BannerItem>
            <div>
              <div className='inner-box slide1'>
                <h2>Só constrói uma empresa única quem, antes,</h2>
                <p>encontra o melhor de si, de sua equipe, do negócio e entrega o melhor para o cliente.</p>
                <BtnAnchor className="button-banner" target="_blank" href="https://api.whatsapp.com/send?phone=5511982629265" rel="noreferrer">Vamos conversar</BtnAnchor>
              </div>
            </div>
            <img src="/assets/banner_4.webp" alt="Só constrói uma empresa única quem, antes, encontra o melhor de si, de sua equipe, do negócio e entrega o melhor para o cliente." />
          </BannerItem>
          <BannerItem>
            <div>
              <div className='inner-box slide2 center'>
                <div>
                  <h2 className='orange'>Faça a sua metanoia</h2>
                  <p>e transforme a sua gestão.</p>
                  <BtnAnchor className="button-banner" target="_blank" href="https://api.whatsapp.com/send?phone=5511982629265" rel="noreferrer">Vamos conversar</BtnAnchor>
                </div>
              </div>
            </div>
            <img src="/assets/banner_5.webp" alt="Faça a sua metanoia e transforme a sua gestão." />
          </BannerItem>
          {/* <BannerItem>
            <div>
              <div className='inner-box slide3 center'>
                <div>
                  <h2>Descubra a Economia ao Natural,</h2>
                  <p>onde a fórmula do lucro é outra.</p>
                  <BtnAnchor className="button-banner" target="_blank" href="https://api.whatsapp.com/send?phone=5511982629265" rel="noreferrer">Vamos conversar</BtnAnchor>
                </div>
              </div>
            </div>
            <img src="/assets/banner_6.webp" alt="Descubra a Economia ao Natural, onde a fórmula do lucro é outra." />
          </BannerItem> */}
        </Glider>
      </div>
      <div className="fixed-tabs-whats">
        <div className="tab-whatsphone">
          <div className="tab-whatsphone-icon">
            <a target="_blank" href="https://api.whatsapp.com/send?phone=5511982629265" rel="noreferrer"><strong>VAMOS CONVERSAR</strong></a>
          </div>
        </div>          
      </div>
      <Transformation id="desafio" className='black'>
        <Container>
          <HalfBox>
            <h2 className='orange'>A pressão que existe no cotidiano <Orange>é inevitável</Orange></h2>
            <p>Um dia normal envolve um grau elevado de estímulo, <br />dúvida e desassossego.</p>
            <p><Orange>Como lidar com tudo isso,</Orange> diante <br />do desafio de construir riquezas?</p>
            <BtnAnchor className="button-banner" target="_blank" href="https://api.whatsapp.com/send?phone=5511982629265" rel="noreferrer">Vamos conversar</BtnAnchor>
          </HalfBox>
        </Container>
      </Transformation>
      <Banner />
      <Transformation id="transformacao">
        <Container>
          <HalfBox className='bigger'>
            <h2>Oferecemos experiências de educação <Green>transformadoras para líderes e empresas,</Green> desde 1999.</h2>
            <p>Notamos padrões de buscas e desejos nas lideranças, independentemente do tamanho e do segmento da empresa.</p>
            <p><Green>Com qual você se identifica?</Green></p>
          </HalfBox>
          <Cards>
            <Card>
              <h3>Cultura emprendedora</h3>
              <ul>
                <li>Falta um norte para alinhar empresa, colaboradores e clientes.</li>
                <li>Desejo fidelizar meus clientes.</li>
                <li>Preciso de projetos que gerem real valor para a empresa.</li>
              </ul>
              <ButtonBox className="bottom-box">
                <Button small onClick={(e) => { setSelectedVideo('https://player.vimeo.com/video/722557314?h=7b5e44d715'); scrollToElement(e, 'videoTalks')}}>Escolha</Button>
              </ButtonBox>
            </Card>
            <Card>
              <h3>Gestão de valor</h3>
              <ul>
                <li>Líderança de alto desempenho.</li>
                <li>Crescemos e não temos mais braços.</li>
                <li>Relação de confiança entre liderança, equipe e clientes.</li>
                <li>Pressão por resultado e qualidade das relações.</li>
              </ul>
              <Margin40 />
              <ButtonBox className="bottom-box">
                <Button small onClick={(e) => { setSelectedVideo('https://player.vimeo.com/video/722557592?h=6a30b0ff7c'); scrollToElement(e, 'videoTalks')}}>Escolha</Button>
              </ButtonBox>
            </Card>
            <Card>
              <h3>Imagem real, não miragem</h3>
              <ul>
                <li>Acho que não estou enxergando algo.</li>
                <li>Está tudo bem, mas sinto uma inquietação.</li>
                <li>Alta rotatividade na equipe.</li>
              </ul>
              <ButtonBox className="bottom-box">
                <Button small onClick={(e) => { setSelectedVideo('https://player.vimeo.com/video/722558160?h=1ce2bfccdf'); scrollToElement(e, 'videoTalks')}}>Escolha</Button>
              </ButtonBox>
            </Card>
            <Card>
              <h3>Geração de riqueza</h3>
              <ul>
                <li>Evolução pessoal e profissional.</li>
                <li>Empresa humana e próspera.</li>
                <li>Contribuir com a comunidade, a sociedade e o planeta.</li>
              </ul>
              <ButtonBox className="bottom-box">
                <Button small onClick={(e) => { setSelectedVideo('https://player.vimeo.com/video/722557998?h=92c4536cbc'); scrollToElement(e, 'videoTalks')}}>Escolha</Button>
              </ButtonBox>
            </Card>
          </Cards>
          <Margin40  id="videoTalks" />
        </Container>
      </Transformation>
      <Videos>
        <Container>
          <div className='video-list'>
          <iframe title="vimeo-player" className='video-frame' src={selectedVideo} width="1000" height="560" frameBorder="0" allowFullScreen></iframe>
          </div>
          <p className='title'>Clique nos vídeos a seguir e confira.</p>
          <div className='video-list'>
            <div className='video-items'  onClick={() => setSelectedVideo('https://player.vimeo.com/video/722557592?h=6a30b0ff7c') }>
              <img src="/assets/v1.webp" alt="Ter uma equipe que pensa e vive os mesmos valores" />
              <p>Ter uma equipe que pensa e vive os mesmos valores</p>
            </div>
            <div className='video-items' onClick={() => setSelectedVideo('https://player.vimeo.com/video/722557769?h=e027c0ddba') }>
              <img src="/assets/v2.webp" alt="Do dia a dia atropelado a um outro olhar sobre os negócios" />
              <p>Do dia a dia atropelado a um outro olhar sobre os negócios</p>
            </div>
            <div className='video-items' onClick={() => setSelectedVideo('https://player.vimeo.com/video/722557998?h=92c4536cbc') }>
              <img src="/assets/v3.webp" alt="Time com alta capacidade de realização" />
              <p>Time com alta capacidade de realização</p>
            </div>
          </div>
          <div className='video-list'>
            <div className='video-items' onClick={() => setSelectedVideo('https://player.vimeo.com/video/722557314?h=7b5e44d715') }>
              <img src="/assets/v4.webp" alt="Abismo entre liderança e equipe" />
              <p>Abismo entre liderança e equipe</p>
            </div>
            <div className='video-items' onClick={() => setSelectedVideo('https://player.vimeo.com/video/722558160?h=1ce2bfccdf') }>
              <img src="/assets/v5.webp" alt="Verdadeira riqueza" />
              <p>Verdadeira riqueza</p>
            </div>
          </div>
          <ButtonBox>
            <BtnAnchor className="button-banner" target="_blank" href="https://api.whatsapp.com/send?phone=5511982629265" rel="noreferrer">Vamos conversar</BtnAnchor>
          </ButtonBox>
        </Container>
      </Videos>
      <Leadership />
      <Education />
      <Scheduler />
      <Leader id="lideranca">
        <Container>
          <h2>A Metanoia da Liderança</h2>
          <p className="title">“A empresa se transforma à medida que o líder se transforma.”</p>
          <p className="description">Você viverá experiências de educação capazes de proporcionar a expansão da consciência e o desenvolvimento de competências ligadas à liderança e à gestão de negócios e relações.</p>
          <Cards className="cards">
            <Card>
              <h3>Liderança<br /><span>+</span></h3>
              <ul>
                <li>Liderança de alto valor.</li>
                <li>Inquietação do líder.</li>
                <li>Equilíbrio pessoal e profissional.</li>
              </ul>
            </Card>
            <Card>
              <h3>Gestão humanizada<br /><span>+</span></h3>
              <ul>
                <li>Empresa humana e próspera.</li>
                <li>Os pontos cegos da liderança.</li>
                <li>Relação de confiança.</li>
              </ul>
            </Card>
            <Card>
              <h3>Gestão empreendedora<br /><span>+</span></h3>
              <ul>
                <li>Criação de valor para a empresa.</li>
                <li>Crescimento estruturado.</li>
                <li>Impacto no mercado e na sociedade.</li>
              </ul>
            </Card>
            <Card>
              <h3>Gestão de resultados<br /><span>+</span></h3>
              <ul>
                <li>Fidelização de clientes.</li>
                <li>Retenção da equipe.</li>
                <li>Resultados e relação.</li>
              </ul>
            </Card>
          </Cards>
          <p className="footerTitle">A Metanoia está preparada para oferecer uma educação transformadora para <Green>100% das pessoas que fazem parte da sua empresa.</Green></p>
          <ButtonBox>
            <BtnAnchor className="button-banner" target="_blank" href="https://api.whatsapp.com/send?phone=5511982629265" rel="noreferrer">Vamos conversar</BtnAnchor>
          </ButtonBox>
          <Margin40 />
        </Container>
      </Leader>
      <Container id="presenca">
        <Container>
          <SquareTitle>Empresas que vivem a Metanoia</SquareTitle>
          <Glider
            hasArrows
            resizeLock
            slidesToShow={1}
            slidesToScroll={1}
            scrollLock={1}
          >
            <CompanyList>
              <div><img src="/assets/clientes/metanoia-cliente-benassi.webp" alt="Benassi" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-bozelli.webp" alt="Bozelli" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-btflex.webp" alt="Btflex" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-ciranda-cultural.webp" alt="Ciranda Cultural" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-creative-display.webp" alt="Creative Display" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-da-santa.webp" alt="Da Santa" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-dois-cunhados-hortifruti.webp" alt="Dois Cunhados Hortifruti" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-ebco.webp" alt="EBCO" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-flormel.webp" alt="Flormel" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-futura-tintas.webp" alt="Futura Tintas" /></div>
            </CompanyList>
            <CompanyList>
              <div><img src="/assets/clientes/metanoia-cliente-granado.webp" alt="Granado" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-happybiz.webp" alt="HappyBiz" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-inovabh.webp" alt="Inovabh" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-john-richard.webp" alt="John Richard" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-minnas.webp" alt="Minnas" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-oceano.webp" alt="Oceano" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-padaria-real.webp" alt="Padaria Real" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-plasticoville.webp" alt="Plasticoville" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-smartfit.webp" alt="Smartfit" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-soneda.webp" alt="Soneda" /></div>
            </CompanyList>
            <CompanyList>
              <div><img src="/assets/clientes/metanoia-cliente-universal-chemical.webp" alt="Universal Chemical" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-viacredi.webp" alt="ViaCredi" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-volo.webp" alt="Volo" /></div>
              <div><img src="/assets/clientes/metanoia-cliente-w12.webp" alt="W12" /></div>
              <div><img src="/assets/clientes/colegio-soter.png" alt="colegio soter" /></div>
            </CompanyList>
          </Glider>
          <ButtonBox>
            <BtnAnchor className="button-banner" target="_blank" href="https://api.whatsapp.com/send?phone=5511982629265" rel="noreferrer">Vamos conversar</BtnAnchor>
          </ButtonBox>
          <Margin40 />
        </Container>
      </Container>
      <Team id="equipe">
        <h2>Conheça a nossa equipe</h2>
        <div className="parallax"></div>
        <div className="meta"></div>
      </Team>
      <Team id="empresa">
        <Container>
          <h2>Conheça a nossa empresa</h2>
          <Videos className='nopadding'>
            <div className='video-list'>
            <iframe className='video-frame' title="vimeo-player" src={selectedCompanyVideo} width="1000" height="560" frameBorder="0" allowFullScreen></iframe>
            </div>
            <div className='video-list'>
              <div className='video-items' onClick={() => setSelectedCompanyVideo('https://player.vimeo.com/video/943719625?h=62ad6acd57&title=0&portrait=0&byline=0') }>
                <img src="/assets/alexandre-zorita.webp" alt="Alexandre Zorita" />
                <p>Alexandre Zorita</p>
              </div>
              <div className='video-items' onClick={() => setSelectedCompanyVideo('https://player.vimeo.com/video/440090608?h=1d0d178b7a') }>
                <img src="/assets/edgard-corona.webp" alt="Edgard Corona" />
                <p>Edgard Corona</p>
              </div>
            </div>
          </Videos>
          <ButtonBox>
            <BtnAnchor className="button-banner" target="_blank" href="https://api.whatsapp.com/send?phone=5511982629265" rel="noreferrer">Vamos conversar</BtnAnchor>
          </ButtonBox>
          <Margin40 />
        </Container>
      </Team>
      <Footer>
        <Container id="contato">
          <div className="footer-left">
            <div className="social">
              <img src="/assets/metanoia-horizontal.png" alt="METANOIA" width="119" />
            </div>
            <div className="options">
              <nav>
                <a href="#" onClick={(e) => scrollToElement(e, 'desafio')}>DESAFIO</a>
                <a href="#" onClick={(e) => scrollToElement(e, 'transformacao')}>TRANSFORMAÇÃO</a>
                <a href="#" onClick={(e) => scrollToElement(e, 'lideranca')}>LIDERANÇA</a>
                <a href="#" onClick={(e) => scrollToElement(e, 'presenca')}>PRESENÇA</a>
                <a href="#" onClick={(e) => scrollToElement(e, 'equipe')}>EQUIPE</a>
                <a href="#" onClick={(e) => scrollToElement(e, 'empresa')}>METANOIA</a>
              </nav>
              <div>
                <address>
                  Rua Sampaio Viana, 390<br/>
                  Paraíso - São Paulo / SP<br/>
                  04004-001<br/>
                  Tel: (11) 3873-1953
                </address>
              </div>
            </div>
            <div className="socials">
              <a href="https://www.facebook.com/metanoiapropositonosnegocios" target="_blank" aria-label="facebook">
                <svg id="Layer_1" enableBackground="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path style={{fill:'#fff'}} className="st0" d="M512,397.1c0,63.5-51.5,114.9-114.9,114.9H114.9C51.5,512,0,460.5,0,397.1V114.9C0,51.5,51.5,0,114.9,0h282.2   C460.5,0,512,51.5,512,114.9V397.1z"/>
                    <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'rgb(47, 47, 47)'}} className="st1" d="M299,164.7c8.6-0.3,17.2-0.1,25.8-0.1c1,0,2.1,0,3.5,0v-44.7c-4.6-0.5-9.4-1.1-14.2-1.3   c-8.8-0.4-17.6-0.8-26.5-0.6c-13.5,0.3-26.2,3.6-37.3,11.5c-12.8,9.2-19.8,22.1-22.4,37.3c-1.1,6.3-1.4,12.8-1.6,19.2   c-0.2,10,0,20,0,30.1v3.8h-42.7v49.9h42.5v125.4h51.9V269.9h42.4c2.2-16.6,4.3-33,6.5-50.1c-3.4,0-6.5,0-9.5,0   c-12.1,0.1-39.7,0-39.7,0s0.1-24.7,0.4-35.5C278.5,169.6,287.3,165.1,299,164.7z"/>
                  </g>
                </svg>
              </a>
              <a href="https://www.linkedin.com/company/metanoia---prop%C3%B3sito-nos-neg%C3%B3cios/" target="_blank" aria-label="linkedin">
                <svg enableBackground="new 0 0 50 50" id="Layer_1" version="1.1" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                  <path d="M45,1H5C2.8,1,1,2.8,1,5v40c0,2.2,1.8,4,4,4h40c2.2,0,4-1.8,4-4V5C49,2.8,47.2,1,45,1z" fill="#fff"/>
                  <g id="in">
                    <path d="M21.3,20.7h5.2v2.6l0,0c0.7-1.4,2.6-2.6,5.2-2.6c5.5,0,6.1,3.1,6.1,7.8V38h-5.2v-8.7   c0-2,0.2-4.3-2.6-4.3c-3.5,0-3.5,2.1-3.5,4.3V38h-5.2L21.3,20.7L21.3,20.7z" fill="rgb(47, 47, 47)" id="n_1_"/>
                    <rect fill="rgb(47, 47, 47)" height="17.3" id="_x7C__1_" width="5.2" x="12.7" y="20.7"/>
                    <circle cx="15.3" cy="15" fill="rgb(47, 47, 47)" id="_x28____x29__1_" r="3"/>
                  </g>
                </svg>
              </a>
              <a href="https://www.instagram.com/metanoiapropositonosnegocios/" target="_blank" aria-label="instagram">
                <svg enableBackground="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path d="M505,257c0,35.8,0.1,71.6,0,107.5c-0.2,52-24.4,90.5-67.6,117.7C412.1,498,384,505,354.2,505   c-65.2,0-130.3,0.3-195.5-0.1c-45.3-0.3-84.3-16.3-115.2-49.9c-19.1-20.8-30.5-45.3-33.8-73.6c-0.7-6-0.8-11.9-0.8-17.9   c0-71.3-0.1-142.6,0-213.9C9.2,97.5,33.4,59,76.6,31.8C102.1,15.9,130.3,9,160.3,9c65,0,130-0.3,195,0.1   c45.5,0.3,84.6,16.4,115.5,50.2c18.9,20.7,30.2,45.2,33.4,73.2c1.3,11,0.7,22,0.8,32.9C505.1,196,505,226.5,505,257z M46,257   c0,36.7-0.1,73.3,0,110c0.1,25.2,9.3,46.9,26.5,64.9c23.1,24.1,51.9,35.8,85,36c65.7,0.4,131.3,0.1,197,0.1   c21.2,0,41.4-4.6,59.8-15.2c34.4-19.7,53.8-48.7,53.8-89.3c0-72.2,0-144.3,0-216.5c0-25-9.1-46.6-26.2-64.5   c-22.9-24.2-51.8-36.1-84.8-36.3C290.7,45.7,224.4,46,158,46c-20.7,0-40.3,4.9-58.3,15.1C65.4,80.9,45.9,109.9,46,150.5   C46,186,46,221.5,46,257z" fill="#fff"/>
                    <path d="M257.3,363c-64.6,0-116.4-51.6-116.3-116c0.1-62.7,52.6-114.1,116.7-114c64.4,0,116.4,51.7,116.3,115.5   C373.9,311.7,321.6,363,257.3,363z M257.3,326c43.9,0,79.7-34.9,79.7-77.8c0-43.1-35.5-78.2-79.3-78.2c-43.9,0-79.7,34.9-79.7,77.8   C178,290.9,213.5,326,257.3,326z" fill="#fff"/>
                    <path d="M363,123.6c0-14.2,10.9-25.6,24.5-25.6c13.6,0,24.5,11.5,24.5,25.6c0,13.9-10.9,25.3-24.3,25.4   C374.1,149.1,363,137.8,363,123.6z" fill='#fff'/>
                  </g>
                </svg>
              </a>
              <a href="https://www.youtube.com/channel/UCDnrYsP_jWRpg_1mUdOrgPA" target="_blank" aria-label="twitter">
                <svg enableBackground="new 0 0 128 128" id="Social_Icons" version="1.1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                  <g id="_x34__stroke">
                    <g id="Youtube_1_">
                      <rect clipRule="evenodd" fill="none" fillRule="evenodd" height="128" width="128"/>
                      <path clipRule="evenodd" d="M126.72,38.224c0,0-1.252-8.883-5.088-12.794    c-4.868-5.136-10.324-5.16-12.824-5.458c-17.912-1.305-44.78-1.305-44.78-1.305h-0.056c0,0-26.868,0-44.78,1.305    c-2.504,0.298-7.956,0.322-12.828,5.458C2.528,29.342,1.28,38.224,1.28,38.224S0,48.658,0,59.087v9.781    c0,10.433,1.28,20.863,1.28,20.863s1.248,8.883,5.084,12.794c4.872,5.136,11.268,4.975,14.116,5.511    c10.24,0.991,43.52,1.297,43.52,1.297s26.896-0.04,44.808-1.345c2.5-0.302,7.956-0.326,12.824-5.462    c3.836-3.912,5.088-12.794,5.088-12.794S128,79.302,128,68.868v-9.781C128,48.658,126.72,38.224,126.72,38.224z M50.784,80.72    L50.78,44.501l34.584,18.172L50.784,80.72z" fill="#fff" fillRule="evenodd" id="Youtube"/>
                    </g>
                  </g>
                </svg>
              </a>
            </div>
          </div>
          <div className="footer-right">
            <h3>Fale conosco</h3>
            <p>Se você tem dúvidas sobre a transformação que a metanoia
            pode proporcionar para você ou sua empresa, nossa equipe de 
            educadores está pronta para ajudar.</p>
            <form id="contato" className="form-horizontal" role="form" onSubmit={(event) => handleSubmit(event)}>
              <input required id="completedName" name="completedName" type="text" placeholder='Nome completo' title="Digite o nome completo!" />
              <input required id="email" name="email" type="email" placeholder='E-mail' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" title="Digite um e-mail válido!" />
              <input required id="phoneNumber" name="phoneNumber" type="text" placeholder='Telefone (Whatspp)' pattern="[0-9]{11}" title="Digite um celular válido!"  />
              <div className="g-recaptcha" data-sitekey="6LdCbhohAAAAAGW0sshladgig0l5UQw6Iz5X9vYT"></div>
              <Button type="submit">VAMOS CONVERSAR</Button>
              <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response"></input>
            </form>
          </div>
        </Container>
      </Footer>
    </Site>
  );
}

export default App;
