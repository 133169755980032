
import React from 'react';
import styled from 'styled-components'

import {
  Center, BtnAnchor,
  Cards, Card, ButtonBox,
} from './atoms'

const EducationWrapper = styled.div`
  background: #02b156;
  padding: 20px 0;
`;

const MiddleBox = styled.div`
  border-top: 1px solid #02b156;
  height: ${props => props.second ? '200px' : '160px'};
  font-size: 24px;

  @media(max-width: 900px) {
    height: auto;
  }

  + :last-child {
    border-top: 1px solid #02b156;
  }
`;

const EducationBanner = styled.div`
  display: flex;
  justify-content: space-between;

  img {
    margin-right: 20px;

  }

  h3 {
    font-size: 37px;
    margin-top: 0;
    line-height: 48px;

    @media(max-width: 900px) {
      text-align: left;
      font-size: 26px;
      line-height: 36px;
      padding-top: 20px;
    }
  }

  p {
    font-size: 30px;
    color: #000;

    @media(max-width: 900px) {
      text-align: left;
      font-size: 18px;
    }
  }
  
  @media(max-width: 900px) {
    display: block;
    text-align: center;
  }
`;

const CardHeader = styled.div`
  font-size: 30px;
  text-align: center;
  padding-bottom: 20px;

  p {
    margin: 0;
    font-size: 38px;
    font-weight: bold;
    color: #02b156;
    line-height: 26px;
  }
`;

const Hilighted = styled.p`
  font-size: 24px;
  padding: 20px 0 0;
  margin: 0;
  font-weight: bold;
`;

const LonelyText = styled.p`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 20px 0 0;
  margin: 0;

  @media(max-width: 900px) {
    font-size: 16px;
  }
`

function App() {

  return (
  <EducationWrapper>
    <Center>
      <EducationBanner>
        <div>
          <img src="/assets/big-black-arrows.png" alt="METANOIA"/>
        </div>
        <div>
          <h3>Educação empresarial transformadora para 100% da empresa.</h3>
          <p>Você e sua equipe viverão experiências de educação capazes de proporcionar a expansão da consciência e do desenvolvimento de competências ligadas à liderança e a gestão de negócios e relações.</p>
        </div>
      </EducationBanner>
      <Cards>
        <Card>
          <CardHeader>
            <p>metanoia</p>
            <span><b>meta</b>gestão</span>
          </CardHeader>
          <MiddleBox>
            <p>Alinhamento das lideranças na tríade corpo, mente e alma para a construção de um desafio comum.</p>
          </MiddleBox>
          <MiddleBox second>
            <p>Impactos na visão sobre o negócio, descoberta do melhor potencial da empresa e da própria liderança.</p>
          </MiddleBox>
          <Hilighted>Equipe de gestão.</Hilighted>
        </Card>
        <Card>
          <CardHeader>
            <p>metanoia</p>
            <span><b>meta</b>riqueza</span>
          </CardHeader>
          <MiddleBox>
            <p>Consciência e competências para o exercício da liderança metanoica.</p>
          </MiddleBox>
          <MiddleBox second>
            <p>Impactos no modelo mental de abundância e no desejo de construir uma cultura humanizada.</p>
          </MiddleBox>
          <Hilighted>Lideranças e herdeiros.</Hilighted>
        </Card>
        <Card>
          <CardHeader>
            <p>metanoia</p>
            <span><b>liderança de alto valor</b></span>
          </CardHeader>
          <MiddleBox>
            <p>Processo de educação destinado às lideranças intermediárias.</p>
          </MiddleBox>
          <MiddleBox second>
            <p>Impacto no comprometimento da equipe, qualidade de diálogo e relacionamento, fidelização dos clientes para atingir os resultados.</p>
          </MiddleBox>
          <Hilighted>Lideranças atuais e potenciais.</Hilighted>
        </Card>
      </Cards>
      <LonelyText>Estamos à sua disposição para um bate papo e juntos fazermos um diagnóstico das suas necessidades e identificar aonde podemos contribuir.</LonelyText>
      <ButtonBox>
        <BtnAnchor className="button-banner" target="_blank" href="https://api.whatsapp.com/send?phone=5511982629265" rel="noreferrer">Quero saber mais ...</BtnAnchor>
      </ButtonBox>
    </Center>
  </EducationWrapper>
  );
}

export default App;
