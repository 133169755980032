
import React from 'react';
import styled from 'styled-components'

import {
  Center, BtnAnchor, ButtonBox,
} from './atoms'

const EducationWrapper = styled.div`
  background: #2f2f2f;
  padding: 0 0 20px;
`;

const LeadershipBanner = styled.div`
  background: #000;
`;

const LeadershipBannerGrid = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media(max-width: 900px) {
    display: block;
    text-align: center
  }

  p {
    font-size: 24px;

    @media(max-width: 900px) {
      font-size: 18px;
      margin: 0;
      padding-bottom: 20px;
    }
  }

  div {
    padding: 15px 0;
    display: flex;

    @media(max-width: 900px) {
      display: block;
    }
    

    h3 {
      padding: 5px 30px;
      font-size: 32px;
      line-height: 30px;
      margin: 0;

      span {
        color: #02b156;
        font-size: 36px;
      }
    }

    img {
      height: 67px;
    }
  }
`;

const GridWrapper = styled.div`
  border-top: 1px solid #7d7d7d;
  border-bottom: 1px solid #7d7d7d;
  display: grid;
  grid-template-columns: 1fr 60px 1fr;
  font-size: 22px;
  font-weight: bold;

  @media(max-width: 900px) {
    font-size: 18px;
  }

  div {
    padding 10px 0;

    img {
      padding-top: 3px;
    }
  } 

  .white {
    text-align: end;
    padding-right: 20px;
  }

  .second {
    padding-left: 16px;
  }

  .green {
    color: #02b156;
  }

  .orange {
    color: #f78734;
  }
`;

const Title = styled.h3`
  font-size: 28px;
  text-align: center;
`;

function App() {

  return (
  <EducationWrapper>
    <LeadershipBanner>
      <Center>
        <LeadershipBannerGrid>
          <div>
            <img src="/assets/big-green-arrows.png" alt="METANOIA"/>
            <h3>LIDERANÇA <br /><span>metanoica</span></h3>
          </div>
          <p>Aquela que transforma <br />enquanto se transforma.</p>
        </LeadershipBannerGrid>
      </Center>
    </LeadershipBanner>
    <Center>
      <Title>É capaz de transformar:</Title>
      <GridWrapper>
        <div className="white">Grupo de trabalho</div>
        <div><img src="/assets/small-green-arrows.png" alt="METANOIA"/></div>
        <div className="second green">Equipe de alto desempenho</div>
        <div className="white">Resultados financeiros</div>
        <div><img src="/assets/small-orange-arrows.png" alt="METANOIA"/></div>
        <div className="second orange">Riquezas plenas</div>
        <div className="white">Rotina</div>
        <div><img src="/assets/small-green-arrows.png" alt="METANOIA"/></div>
        <div className="second green">Trabalho com significado</div>
        <div className="white">Tarefas e afazeres</div>
        <div><img src="/assets/small-orange-arrows.png" alt="METANOIA"/></div>
        <div className="second orange">Propósito</div>
        <div className="white">Normas e regras</div>
        <div><img src="/assets/small-green-arrows.png" alt="METANOIA"/></div>
        <div className="second green">Valores e condutas</div>
        <div className="white">Preço</div>
        <div><img src="/assets/small-orange-arrows.png" alt="METANOIA"/></div>
        <div className="second orange">Valor</div>
        <div className="white">Desconto</div>
        <div><img src="/assets/small-green-arrows.png" alt="METANOIA"/></div>
        <div className="second green">Diferencial</div>
        <div className="white">Sobrevivência</div>
        <div><img src="/assets/small-orange-arrows.png" alt="METANOIA"/></div>
        <div className="second orange">Prosperidade</div>
        <div className="white">Crise</div>
        <div><img src="/assets/small-green-arrows.png" alt="METANOIA"/></div>
        <div className="second green">Equilíbrio</div>
        <div className="white">Repetição</div>
        <div><img src="/assets/small-orange-arrows.png" alt="METANOIA"/></div>
        <div className="second orange">Inovação</div>
      </GridWrapper>
      <ButtonBox>
        <BtnAnchor className="button-banner" target="_blank" href="https://api.whatsapp.com/send?phone=5511982629265" rel="noreferrer">Quero saber mais ...</BtnAnchor>
      </ButtonBox>
    </Center>
  </EducationWrapper>
  );
}

export default App;
